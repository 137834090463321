
.previous{
  /* animation: 1.5s anim-fadeOut ease-out;   */
  animation: 2s anim-lineUp-prev ease-out ;
  opacity: 0.6;
}


.current  {
  /* animation: 1.5s anim-lineUp ease-in;  */
  animation: 1s anim-lineUp-current ease-out ;
  opacity: 1;
}


.next {
  /* animation: 1.5s anim-fadeIn ease-out;  */
  animation: 1s anim-lineUp-next ease-out ;

  opacity: 1;
}

@keyframes anim-lineUp-prev {
  0% {
    transform: translateY(50%);
    opacity: 1;
    
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    transform: translateY(0%);
  }
  100% {
    opacity: 0.6;
    transform: translateY(0%);
    
  }
}

@keyframes anim-lineUp-current {
  0% {
    transform: translateY(50%);
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes anim-lineUp-next {
  0% {
    transform: translateY(50%);
    opacity: 1;
  }
  
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}