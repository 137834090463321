.square-box {
width: 100px; /* Set the width of the square box */
  height: 100px; /* Set the height of the square box relative to its width (creates a square) */
  background-color: white; /* Set the background color of the square box */
  border: 1px solid black; /* Add border for better visibility */
  text-align: center; /* Center the content horizontally */
  line-height: 100px;/* Center the content vertically */
    border-radius: 10px; /* Add border radius to create rounded corners */
cursor : pointer;
}


.square-box.clicked {
  border: 2px solid blue; /* Add blue border when clicked */
}
.box-text {
  display: block;
  margin-top: -30px; /* Adjust the spacing between the box and the text */
  font-size: 12px; /* Adjust the font size of the text */
  color:"black"
}

.custom-button {
    height:"40px";
    width: "110px" ;
  color: black;
  font-weight: bold;
}

.with-scroll {
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
}



.dropdown-menu-container {
    max-height: 200px; /* Adjust the maximum height as needed */
    overflow-y: auto;
}

.font-link {
   font-family: 'Hanalei Fill', cursive;
 }

 .font-link-2 {
   font-family: 'Lato', cursive;
 }


 .noto-sans-elymaic-regular {
  font-family: "Noto Sans Elymaic", sans-serif;
 
 }

/* .crimson-pro {
   font-family: 'Crimson Pro', serif;
 } */ 
@font-face {
  font-family: 'fabrands';
  src: url('../../../assets/fonts/fa-brands-400.woff') format('woff'),
       url('../../../assets/fonts/fa-brands-400.woff2') format('woff2'),
              url('../../../assets/fonts/fa-brands-400.ttf') format('ttf');

}

/* @font-face {
  font-family: 'YourCustomFont2';
  src: url('path/to/font2.woff2') format('woff2'),
       url('path/to/font2.woff') format('woff');
} */
.fabrands {
  font-family: 'fabrands', serif;
}




.noto-sans-elymaic-regular {
  font-family: "Noto Sans Elymaic", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.roboto-mono {
  font-family: "Roboto Mono", monospace;
    font-weight: 400;
  font-style: normal;
}


.danfo{
  font-family: "Danfo", serif;
   font-weight: 400;
  font-style: normal;
}