.bg-gradient-teal-green {
    background: linear-gradient(to right, #6cc6c1, #2b6242);
  }
  
  .bg-gradient-blue {
    background: linear-gradient(to right, #68b9fc, #1f5486);
  }
  
  .bg-gradient-red {
    background: linear-gradient(to right, #fe8e8e, #a12b2b);
  }
  
  .bg-gradient-yellow {
    background: linear-gradient(to right, #f6da74, #977021);
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 10px;
    white-space: nowrap;
    position: relative;


   


  }
  
  