.slide-combined {
  position: relative;
  display: block;
  width: 100wh;
  height: 100vh;
  overflow: hidden;
  /* background-image: url('https://getwallpapers.com/wallpaper/full/b/d/8/1244386-gorgerous-1900-x-1080-hd-wallpaper-1920x1080-iphone.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* filter: grayscale(1) */
  /* border-radius: 5px; */
}

.slide-container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: #1C1124; */
  /* background-color: rgb(84, 84, 84); */
  /* background-image: url('../../../assets/images/bgSlideShow.jpg'); */
  /* background-color: #3498db; A pleasant shade of blue */


}

.container-with-lyrics {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: rgb(152, 15, 15); */

}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

/* .slide-combined:before, .slide-combined:after {
  content: '⥪';
  position: absolute;
  top: 50%;
  left: 1rem;
  z-index: 2;
  width: 2rem;
  height: 2rem;
  background: dodgerblue;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.slide-combined:after {
  content: '⥭';
  left: auto;
  right: 1rem;
} */
/* I haven't found a way for IE and Edge to let me style inputs that way */
.slide-show {
  /* position: relative; */
}

.image {
  /* filter: grayscale(0); */
  width: 100%;
  height: 100%;
}

.animation-disk {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/********************************************************************************************/

@keyframes glisser-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}


@keyframes combiner-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}


/********************************************************************************************/

.combiner {
  /* appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block; */
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* transition: transform ease-in-out 1s; */
  /* animation: flux .5s; */
  z-index: 0;
  display: none;
}

.combiner:focus {
  outline: none;
}

.combiner-checked:before {
  display: none;
  left: 1rem;
}

.combiner-checked:after {
  display: block;
  left: 1rem;
}

.combiner-checked {
  transform: translateY(0%);
  /* transform: translateY(-100%); */
  display: block;
  animation: combiner-in 1s ease-in-out;
  pointer-event: none;
  z-index: 1;
}

@keyframes combiner-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.combiner-checked+.combiner:before {
  left: -3rem;
}

.combiner-checked+.combiner~.combiner:before {
  display: block;
}

/*------------------------------------------------------------------------------------------*/

.glisser {
  /* appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block; */
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* transition: transform ease-in-out 1s; */
  /* animation: flux .5s; */
  z-index: 0;
  display: none;
}

.glisser:focus {
  outline: none;
}

.glisser-checked:before {
  display: none;
  left: 1rem;
}

.glisser-checked:after {
  display: block;
  left: 1rem;
}

.glisser-checked {
  transform: translateY(0%);
  /* transform: translateY(-100%); */
  display: block;
  animation: glisser-in 1s ease-in-out;
  pointer-event: none;
  z-index: 1;
}

@keyframes glisser-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.glisser-checked+.glisser:before {
  left: -3rem;
}

.glisser-checked+.glisser~.glisser:before {
  display: block;
}

/*------------------------------------------------------------------------------------------*/
.flux {
  /* appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block; */
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* transition: transform ease-in-out 1s; */
  /* animation: flux .5s; */
  z-index: 0;
  display: none;
}

.flux:focus {
  outline: none;
}

.flux-checked:before {
  display: none;
  left: 1rem;
}

.flux-checked:after {
  display: block;
  left: 1rem;
}

.flux-checked {
  transform: translateY(0%);
  /* transform: translateY(-100%); */
  display: block;
  animation: flux .5s;
  pointer-event: none;
  z-index: 1;
}

@keyframes flux {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.flux-checked+.flux:before {
  left: -3rem;
}

.flux-checked+.flux~.flux:before {
  display: block;
}

/*-----------------------------------------------------------------------------------------*/
.fondu {
  /* appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block; */
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: none;
}

.fondu:focus {
  outline: none;
}

.fondu-checked:before {
  display: none;
  left: 1rem;
}

.fondu-checked:after {
  display: block;
  left: 1rem;
}

.fondu-checked {
  /* transform: scale(1); */
  transform: translateY(0%);
  display: block;
  animation: fade 0.5s;
  pointer-event: none;
  z-index: 1;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fondu-checked+.fondu:before {
  left: -3rem;
}

.fondu-checked+.fondu~.fondu:before {
  display: block;
}

/*-----------------------------------------------------------------------------------------*/
.slide-show4 {
  /* appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block; */
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* border-radius: 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translateX(-100%);
  transition: transform ease-in-out 1s;
  z-index: 1;
}

.slide-show4:focus {
  outline: none;
}

.checked4:before {
  display: none;
  left: 1rem;
}

.checked4 {
  transform: translateX(0%);
  pointer-event: none;
  z-index: 0;
}

.checked4+.slide-show4:before {
  left: -3rem;
}

.checked4+.slide-show4~.slide-show4:before {
  display: block;
}

/*------------------------------------------------------------------------------------------*/
.song-details {
  display: flex;
  gap: 15px;
  /* align-items: center;
    gap: 15px; 
     background-color: red; */
}

.song-image {
  position: relative;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.song-artiste {
  position: absolute;
  text-transform: uppercase;
}

.song-title-ombre {
  text-shadow: 2px 2px 2px rgb(192, 192, 192);
}

.song-title-elevation {
  text-shadow: 2px 2px 30px rgb(255, 180, 180);
}

.song-title-contour {
  color: transparent;
  -webkit-text-stroke: 1px green;
}

.image-effect-zoom-in-in {
  /* animation: zoom-out 2s infinite; */
  transform: scale(1);
  /* opacity: 0; */
}

@keyframes zoom-in-in {
  0% {
    /* Scale the element to its original size */
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  /* 90% {
      opacity: .7;
    } */
  100% {
    /* Scale the element to 1.5 times its original size */
    transform: scale(1.2);
    /* opacity: .6; */
    /* opacity: .1; */
  }
}

.image-effect-zoom-out {
  /* animation: zoom-out 2s infinite; */
  transform: scale(1.1);
  /* opacity: 0; */
}

@keyframes zoom-out {
  0% {
    /* Scale the element to its original size */
    transform: scale(1.1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  /* 90% {
      opacity: .7;
    } */
  100% {
    /* Scale the element to 1.5 times its original size */
    transform: scale(1);
    /* opacity: .6; */
    /* opacity: .1; */
  }
}

.image-effect-monter {
  position: relative;
  /* animation: monter 11s infinite; */
  transform: scale(1.2);
  /* opacity: 0; */
}

@keyframes monter {
  0% {
    top: -20px
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  100% {
    /* Scale the element to 1.5 times its original size */
    top: -50px;
    /* opacity: .6; */
  }
}

.image-effect-descendre {
  position: relative;
  /* animation: descendre 11s infinite; */
  transform: scale(1.1);
  /* opacity: 0; */
}

@keyframes descendre {
  0% {
    top: -50px
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  100% {
    /* Scale the element to 1.5 times its original size */
    top: 0px;
    /* opacity: .6; */
  }
}

.image-effect-gauche {
  position: relative;
  /* animation: droite 11s infinite; */
  transform: scale(1.1);
  /* opacity: 0; */
}

@keyframes gauche {
  0% {
    left: -50px
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  100% {
    /* Scale the element to 1.5 times its original size */
    left: 0px;
    /* opacity: .6; */
  }
}

.image-effect-droite {
  position: relative;
  /* animation: droite 11s infinite; */
  transform: scale(1.1);
  /* opacity: 0; */
}

@keyframes droite {
  0% {
    left: 50px
  }

  50% {
    opacity: 1;
  }

  /* At the middle of the animation */
  100% {
    /* Scale the element to 1.5 times its original size */
    left: 0px;
    /* opacity: .6; */
  }
}


/*****************************************************************************************************/

/* Border Animation */
.border-animation {
  position: absolute;
  /* top: 1%;
  left: 1%; */
  display: flex;
  /* z-index: 999; */
  align-items: center;
  justify-content: center;
}

.border-animation {
  position: relative;
  height: 200px;
  width: 180px;
  border-radius: 16px;
  /* background-color: #fff; */
  overflow: hidden;
}

.bar {
  float: left;
  width: 15px;
  height: 6px;
  border-radius: 2px;
  background-color: #4b9cdb;
  margin-bottom: 3px;
}

.load-10 .bar {
  animation: loadingJ 4s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loadingJ {

  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(90px, 0);
    background-color: #f5634a;
    width: 25px;
  }
}

/*                       video                              */

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  object-fit: cover;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: 100%;
  height: 100%;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}